import React from "react";
import { AboutIcon } from "../assets/Icons";
import Navbar from "../components/Navbar";
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer";

const AboutScreen = () => {
  const { t } = useTranslation();
  const [prevScrollPos, setPrevScrollPos] = React.useState(window.pageYOffset);
  const [navbarHidden, setNavbarHidden] = React.useState(false);

  React.useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isScrollingDown =
        prevScrollPos < currentScrollPos && currentScrollPos > 50;

      setNavbarHidden(isScrollingDown);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);

  return (
    <>
      <Navbar isInGallery={false} hidden={navbarHidden} shadow />
      <div className="about-margin-top flex flex-col md:flex-row space-x-0 sm:space-x-16 space-y-10 sm:space-y-0 px-5 py-10 sm:px-32 sm:py-20 justify-center items-center h-fit">
        <div className="flex-1 flex-col">
          <h1 className="text-xl sm:text-3xl font-bold">{t("about1")}</h1>
          <p className="text-lg sm:text-xl about-description-my">
            {t("about2")} <br />
            <br />
            {t("about3")}
            <span className="font-bold">{t("about4")}</span>
          </p>
        </div>
        <AboutIcon />
      </div>

      {/* ---------- where we operate? ---------- */}
      <div className="where-we-operate-container">
        <div className="flex flex-col space-y-5 text-white">
          <h1 className="text-xl sm:text-3xl font-bold">
            {t("whereWeOperate")}
          </h1>
          <p>{t("whereWeOperateDescription")}</p>
          <p>{t("whereWeOperateDescription2")}</p>
          <p>{t("whereWeOperateDescription3")}</p>
        </div>
      </div>

      {/* what materials we use? */}
      <div className="materials-container">
        <h2 className="materials-title">{t("materialsWeUseTitle")}</h2>
        <p className="materials-text">{t("materialsDescription")}</p>
        <h3 className="materials-subtitle">{t("highQualityStandardsTitle")}</h3>
        <p className="materials-text">{t("highQualityStandardsDescription")}</p>
        <h3 className="materials-subtitle">{t("featuredMaterialsTitle")}</h3>
        <ul className="materials-list">
          <li>
            <strong>{t("epoxyResins")}:</strong> {t("epoxyResinsDescription")}
          </li>
          <li>
            <strong>{t("polyurethaneCoatings")}:</strong>{" "}
            {t("polyurethaneCoatingsDescription")}
          </li>
          <li>
            <strong>{t("polyureaSystems")}:</strong>{" "}
            {t("polyureaSystemsDescription")}
          </li>
        </ul>
        <div style={{marginTop: 30}}>
          <a
            className="materials-button"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.sika.com/"
          >
            {t("checkOutSikaMaterialsButton")}
          </a>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutScreen;
